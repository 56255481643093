<template>
  <div class="login">
    <div class="login-header">
      <img src="@/assets/image/common/logo.png" alt="">
    </div>
    <div class="wrapper">
      <div class="title">项目管理账号登录</div>
      <div class="login-area">
        <div class="">
          <input type="text" v-model="username" placeholder="请输入用户名">
        </div>
        <div class="">
          <input type="password" v-model="password" placeholder="请输入密码">
        </div>
        <a-row>
          <a-button type="primary" class="login-btn" round @click="login">登录</a-button>
        </a-row>

      </div>
    </div>
  </div>
</template>
<script>
import md5 from 'md5'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  mounted(){

  },
  methods :{
    ...mapActions(['Login', 'Logout']),
    login () {
      if (!this.username) {
        this.$message.error('请输入账号！')
        return
      }
      if (!this.password) {
        this.$message.error('请输入密码！')
        return
      }
      this.Login({
        loginName: this.username,
        password: md5(this.password)
      }).then(() => {
        this.$message.success('登录成功！')
        this.loginSuccess = true
        this.$router.push({ path: '/' })
        setTimeout(() => {
          this.$notification.success({
            message: '欢迎',
            description: `${timeFix()}，欢迎回来`
          })
        }, 1000)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login{
  width: 100vw;
  height: 100vh;
  background-color: #f7f8fa;
  background-image: url("~@/assets/image/login/login_bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-header {
    background: #282B33;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
  }
  .wrapper{
    margin-left: 550px;
    margin-top: -80px;
    width: 410px;
    background-color: #fff;
    border-radius: 10px;
    padding: 60px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

.title{
  color: #252B3A;
  font-size: 26px;
  text-align: center;
}
.login-area{
  margin-top: 30px;
}
.login-area input{
  height: 40px;
  border-radius: 2px;
  padding: 0 20px;
  margin-bottom: 25px;
  background-color: $white;
  border: 1px solid #cccccc;
  outline: none;
  width: 100%;
}
.login-btn{
  width: 290px;
}
.img-code{
  display: flex;
  align-items: center;
  position: relative;
}
.img-code img{
  height: 40px;
  margin-left: 20px;
  border-radius: 50px;
  position: absolute;
  top: 0;
  right: 0;
}
.img-code input{
  flex: 1;
}



</style>
